@import "../globals/media-query.scss";
@import "../globals/colors.scss";

.disclaimer-modal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 1001;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(40, 40, 41, 0.85);

    .disclaimer-modal-content {
        width: 736px;
        background: $white;
        border-radius: 16px;
        @media only screen and (max-width: 767.9px) {
            width: calc(100% - 32px);
        }

        .disclaimer-modal-header {
            position: relative;
            padding: 24px 24px 16px 24px;
            color: $Jaguar;

            p {
                display: inline-block;
                margin: 0;
                font: normal normal 400 20px/32px Sofia Pro;
            }

            .close-btn {
                position: absolute;
                right: 24px;
                top: 24px;
                cursor: pointer;
                padding: 5px;

                img {
                    width: 14px;
                    height: 14px;
                    display: block;
                }

                @media only screen and (max-width: 767.9px) {
                    top: 16px;
                    right: 16px;
                }
            }

            @media only screen and (max-width: 767.9px) {
                padding: 16px 16px 8px 16px;
            }
        }

        .disclaimer-modal-body {
            text-align: justify;
            padding: 0 24px 24px 24px;
            overflow-y: auto;
            color: $secondary_text_grey;
            font: normal normal 400 14px/24px Sofia Pro;
            // max-height: calc(100vh - 145px);
            // min-height: 80vh;
            @media only screen and (max-width: 767.9px) {
                text-align: left;
                padding: 0 16px 16px 16px;
            }

        }
    }
}

.disclaimer-link{
    cursor: pointer;
    &:hover{
        color: $zenyum_blue;
    }
}