@import "../globals/colors.scss";

.country-wrapper {
    display: inline-block;
    cursor: pointer;

    p {
        display: inline-block;
        font-size: 14px;
        line-height: 24px;
        margin-left: 0 !important;
        margin-top: 8px;
        vertical-align: middle;

        span {
            display: inline-block;
            padding-right: 8px;
            margin-right: 8px;
            border-right: 1px solid #E6E6E6;

        }

        a {
            &:hover {
                color: $zenyum_blue;
                text-decoration: none;
            }
        }

        img {
            display: inline-block;
            margin-right: 8px;
            width: 24px;
            height: 24px;
            vertical-align: middle;

        }

    }
}

.country-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .75);
    z-index: 101;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (max-width: 1055.9px) {
        background: $light-grey;
        z-index: 102;
    }

    .country-modal-wrapper {
        width: 100%;
        max-width: 992px;

        @media only screen and (max-width: 1055.9px) {
            position: fixed;
            z-index: 101;
            top: 0;
            left: 0;
            height: 100vh;
        }

        .country-modal-content {
            position: relative;
            width: 100%;
            background: $white;
            border-radius: 16px;
            padding: 56px 32px 32px 32px;

            @media only screen and (max-width: 1055.9px) {
                height: 100%;
                padding: 72px 16px 56px 16px;
                border-radius: 0;
            }

            h3 {
                font-weight: 700;
                font-size: 28px;
                line-height: 40px;
                color: #000000;
                margin-bottom: 0;

                @media only screen and (max-width: 1055.9px) {
                    font-size: 20px;
                    line-height: 32px;
                }


            }

            .country-list-wrapper {
                margin: 0 -8px;
                margin-top: 16px;

                @media only screen and (max-width: 1055.9px) {
                    margin-top: 0;
                    overflow-y: auto;
                    max-height: calc(100vh - 160px);
                }

                ul {
                    list-style-type: none;
                    display: flex;
                    flex-wrap: wrap;
                    margin: 0;
                    padding: 0;

                    li {
                        display: block;
                        padding: 0 8px;
                        margin-top: 16px;
                        &.active{
                            a{
                                border: 1px solid $Jaguar;
                                h5 {
                                    font-weight: 700;
                                }

                            }

                        }

                        a {
                            background: #FAFAFA;
                            border-radius: 8px;
                            display: block;
                            padding: 16px;
                            width: 100%;

                            p {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: $dark_grey;
                                margin: 0 !important;
                                @media only screen and (max-width: 1055.9px) {
                                    font-weight: 400;
                                    font-size: 14px;
                                }
                            }

                            h5 {
                                font-weight: 400;
                                font-size: 16px;
                                line-height: 24px;
                                color: $Jaguar;
                                margin-bottom: 8px;
                                @media only screen and (max-width: 1055.9px) {
                                    font-weight: 400;
                                    font-size: 14px;
                                }
                            }

                            &:hover {

                                p,
                                h5 {
                                    color: $zenyum_blue;
                                }

                                text-decoration: none;
                            }

                        }
                    }
                }
            }

            .close-icon {
                position: absolute;
                top: 16px;
                left: 16px;
                display: block;
                padding: 2px;
                height: 20px;
                line-height: 16px;
                cursor: pointer;

                img {
                    width: 16px;
                    height: 16px;

                }

            }
        }
    }

}