@use '@carbon/grid';
@import "../globals/colors.scss";

@include grid.flex-grid();

footer {
    background: $Jaguar;
    color: #FFFFFF;

    .footer-top {
        padding: 32px 0;

        a {
            text-decoration: none;

            &:hover {
                color: $zenyum_blue;
                text-decoration: none;
            }
        }

        .footer-sec {
            @media only screen and (max-width: 1055.9px) {
                margin-bottom: 32px;
            }

        }

        h4 {
            font-weight: 400;
            font-size: 20px;
            line-height: 32px;
        }

        .footer-list {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                a {
                    font-size: 14px;
                    line-height: 24px;
                    display: block;
                    margin-top: 8px;

                    &:hover {
                        color: $zenyum_blue;
                        text-decoration: none;
                    }
                }
            }
        }

        p {
            display: block;
            font-size: 14px;
            line-height: 24px;
            margin: 0;
            margin-top: 8px;

            a {
                &:hover {
                    color: $zenyum_blue;
                    text-decoration: none;
                }
            }

            img {
                display: inline-block;
                margin-right: 16px;
                width: 24px;
                height: 24px;

            }

        }

        .social-media-icons {
            width: 100%;
            text-align: right;

            @media only screen and (max-width: 1055.9px) {
                text-align: left;
                border-bottom: 1px solid #E6E6E6;
                padding-bottom: 32px;
            }

            a {
                width: 24px;
                display: inline;
                text-align: center;
                margin-left: 16px;

                @media only screen and (max-width: 1055.9px) {
                    margin-left: 0;
                    margin-right: 16px;
                }

                img {
                    height: 20px;
                    width: 24px;
                    margin: 0 auto;
                }
            }

            .footer-logo-vn {
                width: 24px;
                display: inline;
                text-align: center;
                margin-left: 16px;

                @media only screen and (max-width: 1055.9px) {
                    margin-left: 0;
                    margin-right: 16px;
                }

                img {
                    height: 20px;
                    margin: 0 auto;
                }

            }
        }
    }

    .footer-bottom {
        padding-bottom: 32px;

        @media only screen and (max-width: 1055.9px) {
            margin-top: 0;
            padding-bottom: 32px;
        }

        .footer-bottom-right {
            text-align: right;

            @media only screen and (max-width: 1055.9px) {
                display: none;
            }
        }

        .mobile-footer-bottom-right {
            display: none;

            @media only screen and (max-width: 1055.9px) {
                display: block;
                text-align: left;
                margin: 0;

                p {
                    display: block;
                    font-size: 14px;
                    line-height: 24px;
                    margin-bottom: 8px;
                    margin-left: 0px !important;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

        }

        p {
            display: inline-block;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            margin-left: 16px;
            margin-bottom: 0;
            margin-top: 0 !important;

            a {
                &:hover {
                    color: $zenyum_blue;
                    text-decoration: none;
                }
            }
        }
    }

    .iso-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        margin: 32px auto 0 auto;
        padding-top: 32px;
        border-top: 1px solid #E6E6E6;
        width: 100%;

        @media only screen and (max-width: 1055.9px) {
            flex-direction: column;
            align-items: flex-start;
            border: none;
            padding-top: 0;
            padding-bottom: 32px;
        }

        img {
            width: 142px;
            height: 86px;
        }

        p {
            max-width: 440px;
            color: $white;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin: 0;
        }
    }
}